import { useState, useEffect } from 'react';
import AuthResult from '../templates/auth-result/authResult';
import { ProductInfo } from '../templates/product-info';
import PromotionalVideo from '../templates/promotional-video/promotional-video';
import LegalTerms from '../templates/legal-terms/legal-terms';
import ProductImageView from '../templates/product-info/product-image-view/product-image-view';
import ProductCustomPage from '../templates/product-info/product-custom-page/product-custom-page';
import EmptyPage from '../templates/empty-page/empty-page';
import Redirect from '../templates/redirect/redirect';
import tagsApi from 'services/tagsApi';
import {
	experienceMapperHelper,
	geolocateIp,
	queryStringHelper,
} from '../services';

const EXPERIENCE_INFO_VIEWS = [
	'Horizontal',
	'Vertical',
	'Images',
	'Default Page',
];

const REDIRECT_TEMPLATE_REFERENCE = 'Redirect';

export function useTemplateViews(
	product,
	uid,
	counter,
	tamperStatus,
	experienceId
) {
	const [views, setViews] = useState([]);
	const [ready, setReady] = useState(false);
	const [activeView, setActiveView] = useState(0);
	const [cookiesEnabled, setCookiesEnabled] = useState(false);
	const demoMode = false;

	const setNextView = () => {
		setActiveView(activeView + 1);
	};

	const getViewIndex = (viewName, pageReference, contentPageReference) => {
		const viewIndex = pageReference
			? views.findIndex(
					(v) =>
						v.reference === pageReference &&
						(contentPageReference
							? v.contentPageReference === contentPageReference
							: true)
			  )
			: views.findIndex(
					(v) =>
						v.view.name.toString() === viewName &&
						(contentPageReference
							? v.contentPageReference === contentPageReference
							: true)
			  );

		return viewIndex;
	};

	const setViewByName = (viewName, pageReference, contentPageReference) => {
		const viewIndex = getViewIndex(
			viewName,
			pageReference,
			contentPageReference
		);
		if (viewIndex >= 0) setActiveView(viewIndex);
	};

	const getViewContent = (viewName, pageReference, contentPageReference) => {
		const viewIndex = getViewIndex(
			viewName,
			pageReference,
			contentPageReference
		);

		if (viewIndex >= 0) {
			return views[viewIndex];
		}
		return;
	};

	useEffect(() => {
		async function getData() {
			const parseViewWithName = (reference) => {
				switch (reference) {
					case 'AuthResult':
						return AuthResult;
					case 'LegalTerms':
						return LegalTerms;
					case 'PromotionalVideo':
						return PromotionalVideo;
					case 'Horizontal':
						return ProductInfo;
					case 'Vertical':
						return ProductInfo;
					case 'Images':
						return ProductInfo;
					case 'ProductExtendedInfo':
						return ProductImageView;
					case 'ProductPairing':
						return ProductImageView;
					case 'ProductOffers':
						return ProductImageView;
					case 'CigarShopAndorra':
						return ProductInfo;
					case 'Default Page':
						return ProductCustomPage;
					default:
						return null;
				}
			};

			const addImageUrlToIcon = (icon, product) => {
				icon.imageUrl = experienceMapperHelper.getImageUrl(
					icon.iconImageTag,
					product.mediaFiles,
					product.readToken
				);

				return icon;
			};

			const addImageToRatingIcon = (product) => {
				let ratingIcons = {
					emptyIcon: experienceMapperHelper.getImageUrl(
						product.ratingEmptyImageTag,
						product.mediaFiles,
						product.readToken
					),
					halfIcon: experienceMapperHelper.getImageUrl(
						product.ratingHalfImageTag,
						product.mediaFiles,
						product.readToken
					),
					fullIcon: experienceMapperHelper.getImageUrl(
						product.ratingFullImageTag,
						product.mediaFiles,
						product.readToken
					),
				};

				return ratingIcons;
			};

			const addTamperData = (product) => {
				const tamperStatuses = [
					{
						active: product.isTamperOpen,
						tag: product.openTamperImageTag,
						text: product.openTamperText,
						imageUrl: experienceMapperHelper.getImageUrl(
							product.openTamperImageTag,
							product.mediaFiles,
							product.readToken
						),
					},
					{
						active: product.isTamperOpen == false,
						tag: product.closeTamperImageTag,
						text: product.closeTamperText,
						imageUrl: experienceMapperHelper.getImageUrl(
							product.closeTamperImageTag,
							product.mediaFiles,
							product.readToken
						),
					},
					{
						active:
							product.isTamperOpen == null ||
							product.isTamperOpen === undefined,
						tag: product.unknownTamperImageTag,
						text: product.unknownTamperText,
						imageUrl: experienceMapperHelper.getImageUrl(
							product.unknownTamperImageTag,
							product.mediaFiles,
							product.readToken
						),
					},
				];

				return tamperStatuses;
			};

			const addAuthenticationData = (product) => {
				let authenticationStatus = {
					successful: {
						text: product.successfulAuthenticationText,
						tag: product.successfulAuthenticationImageTag,
						imageUrl: experienceMapperHelper.getImageUrl(
							product.successfulAuthenticationImageTag,
							product.mediaFiles,
							product.readToken
						),
					},
					unsuccessful: {
						text: product.unsuccessfulAuthenticationText,
						tag: product.unsuccessfulAuthenticationImageTag,
						imageUrl: experienceMapperHelper.getImageUrl(
							product.unsuccessfulAuthenticationImageTag,
							product.mediaFiles,
							product.readToken
						),
					},
				};
				return authenticationStatus;
			};

			const country = await geolocateIp.getCountry();
			const currentUrl = queryStringHelper.getCurrentUrl();

			if (!ready) {
				tagsApi
					.createScan(
						product,
						uid,
						counter,
						country['countryCode'],
						tamperStatus,
						currentUrl,
						experienceId
					)
					.then((response) => {
						const product = response;

						let templateViews = [];
						if (product) {
							document.title = product.authenticationResult
								? 'HGTag'
								: 'HGTag-NotAuthenticated';
							product.pages
								.filter((page) => page.visible)
								.forEach((page, pageIndex, filteredPages) => {
									templateViews.push({
										view: parseViewWithName(page.reference),
										reference: page.reference,
										contentPageReference: page.contentPageReference,
										props: {
											...page,
											productId: product.id,
											youtubeUrl: product.youtubeUrl,
											productName: product.name,
											productFields: product.fields,
											authenticationResult: product.authenticationResult,
											backgroundImageUrl: experienceMapperHelper.getImageUrl(
												page.backgroundImageTag,
												product.mediaFiles,
												product.readToken
											),
											content: page.content,
											cookiesEnabled: product.cookiesEnabled,
											legalAge: product.legalAge,
											showTitle: product.showTitle,
											template: product.template,
											mediaFiles: product.mediaFiles,
											token: product.readToken,
											pages: product.pages,
											topIcons: product.icons
												.filter((icon) => icon.isTopIcon)
												.map((icon) => addImageUrlToIcon(icon, product)),
											bottomIcons: product.icons
												.filter((icon) => !icon.isTopIcon)
												.map((icon) => addImageUrlToIcon(icon, product)),
											articles: product.articles.map((a) => ({
												...a,
												imageUrl: experienceMapperHelper.getImageUrl(
													a.imageTag,
													product.mediaFiles,
													product.readToken
												),
											})),
											scanId: product.scanId,
											scanType: product.scanType,
											ratingIcons: addImageToRatingIcon(product),
											ratingCompletedResultType:
												product.ratingCompletedResultType,
											ratingGratutideMessage: product.ratingGratutideMessage,
											ratingShareTargetTemplatePageReference:
												product.ratingShareTargetTemplatePageReference,
											ratingShareButtonTitle: product.ratingShareButtonTitle,
											authenticationData: addAuthenticationData(product),
											usesTagTamper: product.usesTagTamper,
											tamperStatuses: addTamperData(product),
											serialNumber: product.serialNumber,
											experienceDefaultData: {
												experienceDefaultBackgroundImage:
													product.experienceDefaultBackgroundImage,
												experienceDefaultImage: product.experienceDefaultImage,
											},
										},
									});
								});

							const hasVisiblePages =
								templateViews.filter(
									(view) =>
										EXPERIENCE_INFO_VIEWS.findIndex(
											(viewRef) => viewRef === view.reference
										) >= 0
								).length > 0;

							if (hasVisiblePages) {
								product.icons
									.filter((icon) => icon.targetImageTag)
									.forEach((icon) => {
										templateViews.push({
											view: ProductImageView,
											props: {
												productName: product.name,
												showTitle: product.showTitle,
												imageUrl: experienceMapperHelper.getImageUrl(
													icon.targetImageTag,
													product.mediaFiles,
													product.readToken
												),
												fontColor:
													product.pages[product.pages.length - 1].fontColor,
												backgroundImageUrl: experienceMapperHelper.getImageUrl(
													product.pages[product.pages.length - 1]
														.backgroundImageTag,
													product.mediaFiles,
													product.readToken
												),
												backgroundColor:
													product.pages[product.pages.length - 1]
														.backgroundColor,
												productInfoViewReference:
													product.pages[product.pages.length - 1].reference,
											},
											reference: icon.id,
										});
									});
							}

							if (
								product.template?.name === REDIRECT_TEMPLATE_REFERENCE &&
								product.redirectUrl
							) {
								templateViews.push({
									view: Redirect,
									props: {
										redirectUrl: product.redirectUrl,
									},
								});
							}
						}

						templateViews.push({
							view: EmptyPage,
							props: {
								backgroundColor:
									product?.experienceDefaultBackgroundColor || '#000000',
								fontColor: product?.experienceDefaultFontColor || 'white',
								fontFamily: product?.experienceDefaultFontFamily,
								image: experienceMapperHelper.getImageUrl(
									product?.experienceDefaultImage,
									product.mediaFiles,
									product.readToken,
									true
								),
								backgroundImage: experienceMapperHelper.getImageUrl(
									product?.experienceDefaultBackgroundImage,
									product.mediaFiles,
									product.readToken,
									true
								),
								message: product?.experienceDefaultMessage,
							},
						});
						setViews(templateViews);
						setCookiesEnabled(product?.cookiesEnabled || false);
						setReady(true);
					});
			}
		}
		!ready && getData();
	}, [counter, product, ready, uid]);

	return {
		views,
		ready,
		activeView,
		setNextView,
		setViewByName,
		cookiesEnabled,
		getViewContent,
		demoMode,
	};
}

export default useTemplateViews;
